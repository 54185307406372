<style lang="sass" scoped>
.container-property
  // z-index: 1000
  .index-nav
    z-index: 1000
  .container-nav
    position: fixed
    left: 0
    top: 0
    width: 70px
    height: 100vh
    z-index: 1000
    background-color: darken(#f8f9fa, 7%)
    // border-right: solid 1px darken(#f8f9fa, 5%)

    .link
      width: 100%
      font-size: 12px
      font-weight: 700
      color: darken(#f8f9fa, 60%) !important
      &.active
        background-color: #f8f9fa

      &:hover
        background-color: #f8f9fa
        text-decoration: none

.container-left
  z-index: 900
  position: fixed
  top: 0px
  left: 70px
  height: 100vh
  // margin-top: 69px
  float: left
  width: 300px

  // position: sticky
  // top: 70px-1px
  // height: 90vh
  .container-left-top
    height: calc(100vh - 60px)
    overflow: scroll

    // background-color: #f8f9fa

  .container-left-bottom
    height: 60px
.container-right
  position: absolute
  right: 0
  // float: right
  // padding-left: 300px
  // margin-top: 69px
  width: calc(100% - 370px)
  height: 100%
  z-index: 1000
  overflow: scroll

.container-customer
  font-size: 12px
  .link
    color: darken(#f8f9fa, 60%)
    font-weight: bold
    line-height: 2rem
    &.active
      color: darken(#f8f9fa, 80%)
      background-color: darken(#f8f9fa, 7%)
.container-filter
  font-size: 12px
  font-weight: 600
  line-height: 1.7rem
  .link
    color: darken(#f8f9fa, 40%)
.container-search
  line-height: 1.7rem
  font-size: 12px
  .link
.container-inactive-top
  position: relative
  top: -6rem
  height: 6rem
  background-color: rgba(248,249,250,.7)
.required
  color: tomato
.container-modal-backdrop
  // background-color: rgba(248,249,250,.5)
  background-color: rgba(0,0,0,.2)
  position: fixed
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  z-index: 2000
.container-modal
  position: fixed
  z-index: 2001
  top: 3rem
  width: 760px
  left: calc(50% - 760px/2)
  .close
    font-size: 24px
  .body
  .text-description
    color: darken(#f8f9fa, 50%)
    font-size: 12px
    font-weight: 600
.container-modal-backdrop-mini
  // background-color: rgba(248,249,250,.5)
  // background-color: rgba(0,0,0,.2)
  background-color: rgba(0,0,0,0)
  position: fixed
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  z-index: 2000
.container-modal-mini
  position: fixed
  z-index: 2001
  top: 137px
  left: 375px
  width: 240px
  // left: calc(50% - 760px/2)
  .close
    font-size: 24px
  .body
  .text-description
    color: darken(#f8f9fa, 50%)
    font-size: 12px
    font-weight: 600
.container-table
  font-size: 12px
  overflow: scroll
  // position: relative
  height: calc(100% - 100px)
  .sticky-header
    position: sticky
    top: -1px
    // left: 0px
    // display: inline-block
    background-color: #fff
    z-index: 1000
    // border-bottom: solid 2px #ccc
    outline: solid 1px #ccc
    border-bottom: 0
</style>
<template lang="pug">
div.container-property
  .container-nav
    //- <div class="d-flex flex-column" style='height: 100%'>
    //-   <div class="p-2">Flex item</div>
    //-   <div class="p-2">Flex item</div>
    //-   <div class="mt-auto p-2">Flex item</div>
    //- </div>
    .d-flex.flex-column(style='height: 100%')
      div(style='height: 300px')
        .d-flex.flex-column
          a.link.p-2.py-4.text-center.text-dark(href='#')
            span(style='font-size: 24px; line-height: 12px; font-weight: 900'): em N
          a.link.p-2.py-4.text-center.text-dark.active(href='#') 고객
          a.link.p-2.py-4.text-center.text-dark(href='#') 메시지
      div.mt-auto()
        .d-flex.flex-column
          a.link.p-2.py-4.text-center.text-dark(href='#') 설정
          a.link.p-2.py-4.text-center.text-dark(href='#') 프로필
          a.link.p-2.py-4.text-center.text-dark(href='#') 도움말



      //- a.link.p-2.py-4.text-center.text-dark(href='#') 설정
      //- a.link.p-2.py-4.text-center.text-dark(href='#') 프로필
      //- a.link.p-2.py-4.text-center.text-dark(href='#') 도움말

  div.container-left
    .container-left-top
      .row.justify-content-center.align-items-center(style='')
        .col
          .p-4.container-customer
            h5 고객
            a.link.pl-2.d-block.active.rounded(href='#') 새 목록 1
              a.float-right.link-config.mr-2(href='#') 설정

            .mb-4
            h5 관련 정보
            p 관련 정보가 없습니다.

    .container-left-bottom.border-top.shadow
      a.link.p-3.d-block(href='#') 새 목록 만들기

  div.container-right.bg-white.shadow-sm
    .container-filter.border-bottom.p-2
      a.link.ml-3(href='#') 전체 (0)
      a.link.ml-3.text-primary.float-right(href='#') 새 필터 만들기
    .container-search.border-bottom.p-2
      form.form-inline.d-inline
        input.form-control.form-control-sm.rounded.bg-light(type='search' style='width: 300px')
        button.btn.btn-default.link-search.text-primary.btn-sm(href='#') 검색하기

      a.link.ml-3.text-primary.float-right(href='#') 메시지 보내기
      a.link.ml-3.text-primary.float-right(href='#') 새 고객 추가하기
    .container-table.async(:class='{done:done}')
      table.table.table-bordered(:style='{width:`${width}px`}')
        thead
          tr
            th.sticky-header.shadow-sm(v-for='col in columns' :style='{width:`${col.width}px`}')
              span(v-if='col.label != `+`') {{col.label}}
                span.float-right(style='color:#ccc') {{col.format}}
              span(v-else style='opacity: 0.5')
                a.text-muted(href='#')
                  b-icon-plus
                  | 맞춤항목 추가
        tbody
          tr
            td
              input.form-control.form-control-sm.border-0.m-0.p-0(type='text' v-model='v1' placeholder='값 입력')
            td
              input.form-control.form-control-sm.border-0.m-0.p-0(type='text' v-model='v2' placeholder='')
            td
              input.form-control.form-control-sm.border-0.m-0.p-0(type='text' v-model='v3' placeholder='')
            td
              input.form-control.form-control-sm.border-0.m-0.p-0(type='text' v-model='v4' placeholder='')
            td
              a(href='#').btn.btn-sm.btn-light.bg-white.border.shadow-sm.py-2.async(
                :class='{done:(v1 || v2 || v3 || v4)}' style='margin: -5px 0 -10px -5px'
              ): strong 새 고객 추가하기
          tr
          tr
    .container-modal-backdrop-mini
    .container-modal-mini
      .body.bg-white.shadow-sm.rounded.border.p-2
        input.form-control.form-control-sm.bg-light(type='text' value='고객ID')
        b-list-group.mt-1.list-group-sm(style='font-size: 12px')
          b-list-group-item.border-0(to='#') 번호
          b-list-group-item.border-0(to='#') 텍스트
          b-list-group-item.border-0.active(to='#') @고객ID
          b-list-group-item.border-0(to='#') @고객이름
          b-list-group-item.border-0(to='#') @고객이메일
          b-list-group-item.border-0(to='#') @고객전화번호
    //- .container-inactive-table
    .row.justify-content-center.align-items-center(style='position: relative; top: -300px')
      .col
        .text-center
          p 새로운 고객 목록을 추가했습니다.
          br
          a(href='#').btn.btn-light.bg-white.border.shadow-sm.py-2: strong 새 고객 추가하기



</template>

<script>


export default {
  name: 'index',
  components: {

  },
  computed: {

  },
  mounted() {
    // this.$store.dispatch('session')
    this.load()
  },
  data() {
    return {
      done: false,
      form: {},
      agree1: 'N',
      agree2: 'Y',
      width: 0,
      v1: '',
      v2: '',
      v3: '',
      v4: '',
      columns: [
        {
          label: '회원번호',
          width: 150,
          format: '@고객ID'
        },
        {
          label: '숙소명',
          width: 150,
          format: '@고객이름'
        },
        {
          label: '이메일',
          width: 150,
          format: '@고객이메일'
        },
        {
          label: '휴대폰',
          width: 150,
          format: '@고객전화번호'
        },
        {
          label: '동의여부',
          width: 150,
          format: '텍스트'
        },
        {
          label: '+',
          width: 400,
        },
        // {
        //   label: '맞춤2',
        //   width: 150,
        // },
      ]
    }
  },
  methods: {
    async load() {
      let sum = 0
      for (const e of this.columns) {
        sum += e.width
      }
      this.width = sum

      try {
        // const r = await this.$http.get('/v1')
        // console.log('ping:', r?.data)

        setTimeout(() => {
          this.done = true
        }, 300);
      } catch (error) {
        console.log({error})
      }
    },
  },
}
</script>
